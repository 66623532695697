import { z } from 'zod';

export const schoolSchema = z.object({
  id: z.string().min(1),
  schoolCode: z.string().min(1),
  name: z.string().min(1),
  phoneNumber: z.string(),
  faxNumber: z.string(),
  postalCode: z.string(),
  address: z.string(),
  cityCode: z.string(),
});

export type School = z.infer<typeof schoolSchema>;
